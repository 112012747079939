import React from 'react'
import Layout from '../components/Layout'
export default function ErrorNotFound() {
    return (
        <Layout pageTitle="404">
            <div>
                <h2>404</h2>
                <p>Sorry this page doesn't exist</p>
            </div>
        </Layout>
    )
}
