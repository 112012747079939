import React from "react"
import Navbar from "./Navbar"
import "../styles/global.css"
import { useStaticQuery, graphql } from "gatsby"
export default function Layout({ pageTitle, children }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          shortTitle
        }
      }
    }
  `)

  return (
    <div className="layout">
      <Navbar />
      <title>{data.site.siteMetadata.shortTitle}: {pageTitle}</title>
      <div className="content">{children}</div>
      <footer>
        <p>Copyright 2021 {data.site.siteMetadata.title}</p>
        <p>Made with ❤️ by Ayzak From MangoTreeMusic</p>
      </footer>
    </div>
  )
}
