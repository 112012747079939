import { Link } from 'gatsby'
import React from 'react'

export default function Navbar() {
    return (
       <nav>
           <h1><Link to="/">Mango Tree</Link></h1>
           <div>
               <Link to="/">Home</Link>
               <Link to="/blog">Blog</Link>
               <Link to="/portfolio">Music Portfolio</Link>
               <Link to="/about">About us</Link>
           </div>
       </nav>
    )
}
